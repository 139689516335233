<template>
  <div style="padding-bottom: 100px">
    <top-fixed></top-fixed>
    <div class="header">
      <div
        class="nav"
        :class="selectIndex == 2 ? 'selectNav' : ''"
        @click="changeIndex(2)"
      >
        {{ $t("hj45") }}
      </div>
      <div
        class="nav"
        :class="selectIndex == 3 ? 'selectNav' : ''"
        @click="changeIndex(3)"
      >
        {{ $t("hj46") }}
      </div>
      <div
        class="nav"
        :class="selectIndex == 4 ? 'selectNav' : ''"
        @click="changeIndex(4)"
      >
        {{ $t("hj47") }}
      </div>
      <div
        class="nav"
        :class="selectIndex == 1 ? 'selectNav' : ''"
        @click="changeIndex(1)"
      >
        {{ $t("hj48") }}
      </div>
    </div>

    <div
      class="conter"
      style="padding: 1rem"
      v-if="selectIndex == 3 || selectIndex == 2"
    >
      <div class="list" v-for="(item, index) in listData1" :key="index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
          "
        >
          <div style="color: #000000; font-weight: 700; font-size: 0.6rem">
            {{ $t("hj13") }}:
            <span style="color: #fa9d45">{{ item.usdt_amount }}</span>
          </div>
          <div
            style="color: #fa9d45; font-size: 0.5rem"
            v-if="selectIndex == 3"
          >
            {{ item.status_name }}
          </div>
        </div>
        <div style="font-size: 0.5rem; color: #cccccc">
          {{ item.created_at }}
        </div>
      </div>
    </div>

    <div
      class="conter"
      style="padding: 1rem"
      v-if="selectIndex == 1 || selectIndex == 4"
    >
      <div class="list" v-for="(item, index) in listData2" :key="index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.3rem;
          "
        >
          <div style="color: #000000; font-weight: 700; font-size: 0.6rem">
            ETH{{ $t("hj14") }}:
            <span style="color: #000">{{ item.amount }}</span>
          </div>
        </div>
        <div style="font-size: 0.5rem; color: #cccccc; margin-bottom: 0.8rem">
          {{ item.created_at }}
        </div>

        <div
          style="
            background: #f4f4f4;
            border: 0.5px solid #dddddd;
            border-radius: 9px;
            box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.08) inset;
            padding: 0.5rem;
          "
        >
          <div style="color: #000000; font-size: 0.6rem; margin-bottom: 0.3rem">
            USDT{{ $t("hj15") }}:
            <span style="color: #fa9d45">{{ item.usdt_amount }}</span>
          </div>
          <div style="font-size: 0.4rem">{{ $t("hj16") }}:{{ item.rate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TopFixed from "../components/TopFixed.vue";
import { reward, withdraw_logs } from '../http/api'
export default {
  name: "AccountView",
  data () {
    return {
      selectIndex: 2,
      listData1: '',
      listData2: ''
    };
  },
  components: { TopFixed },
  mounted () {
    if (this.$route.query.type) {
      console.log(1)
      this.changeIndex(Number(this.$route.query.type))
      this.selectIndex = this.$route.query.type
    } else {
      this.changeIndex(2)
    }
  },
  methods: {
    changeIndex (num) {
      this.rewardData = ''
      this.selectIndex = num
      switch (num) {
        case 1:
          reward({ type: this.selectIndex }).then((res) => {
            this.listData2 = res.data
          })
          break
        case 2:
          reward({ type: this.selectIndex }).then((res) => {
            this.listData1 = res.data
          })
          break
        case 3:
          withdraw_logs().then((res) => {
            this.listData1 = res.data
          })
          break
        case 4:
          reward({ type: this.selectIndex }).then((res) => {
            this.listData2 = res.data
          })
          break
      }
    },
    dataInit () {
      reward({ type: this.selectIndex }).then((res) => {
        console.log(res.data)
      })
    }
  },

};
</script>
<style scoped lang="scss">
.account {
  background-image: url("../assets/img/5-提现记录.png");
  background-size: 7.5rem;

  width: 7.5rem;
  height: 15rem;
  background-position-y: -51px;
}

input {
  background: none;
  outline: none;
  border: none;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 0 0.8rem;
  font-size: 0.6rem;
  border-bottom: 1px solid #dddddd;
  .nav {
    padding: 1rem 0;
  }
  .selectNav {
    border-bottom: 1px solid #fa9d45;
    color: #fa9d45;
    font-weight: 600;
  }
}

.list {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 0.6rem;
  margin-bottom: 0.8rem;
}
</style>